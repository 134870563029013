<template>
  <div class="content d-flex">
    <div
      class="event-detail__iframe mb-8"
      :class="{ 'event-detail__iframe_opened': openFrame }"
    >
      <div
        @click="openFrame = false"
        v-if="openFrame"
        class="event-detail__close-btn"
      >
        <span class="button button_pink button_small">Назад</span>
      </div>

      <iframe
        v-if="getPassword && getStream && Object.keys(getStream).length"
        :id="getStream.stream_id"
        :onload="`fc_load_iframe(this, 'autorun=true&key=${getPassword}')`"
        style="border: none"
        allow="autoplay; fullscreen"
        allowfullscreen
      ></iframe>
    </div>

    <div
      @click="openStream"
      v-if="showStream"
      class="event-detail__translation button button_pink px-6 ma-auto"
    >
      Смотреть трансляцию
    </div>
    <Modal
      class="stream-data"
      v-if="sendData"
      @close="toggleSendData(false)"
      :title="'Заполните данные'"
    >
      <div class="modal__item mb-4">
        <div class="modal-item__title">Email</div>
        <div class="modal-item__value">
          <TextField
            :type="'text'"
            v-model="email"
            :rules="[
              Rules.isRequired(email, 'Нужно заполнить Email'),
              Rules.isCorrectEmail(email, 'Некорректный email'),
            ]"
          ></TextField>
        </div>
      </div>
      <div class="modal__item">
        <div class="modal-item__title">Имя</div>
        <div class="modal-item__value">
          <TextField
            :type="'text'"
            v-model="name"
            :rules="[Rules.isRequired(name, 'Нужно заполнить имя')]"
          ></TextField>
        </div>
      </div>
      <div class="modal__save button button_pink" @click="sendStreamData()">
        Сохранить
      </div>
    </Modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { bus } from "@/main";
import TextField from "@/components/form-elements/TextField.vue";
import Modal from "@/components/pageComponents/cabinet/Modal.vue";
import validate from "@/mixins/validate";
export default {
  name: "Stream",
  components: {
    TextField,
    Modal,
  },
  mixins: [validate],
  data: () => ({
    openFrame: false,
    sendData: false,
    email: "",
    name: "",
  }),
  computed: {
    ...mapGetters(["getStream", "user", "Rules", "getPassword"]),
    showStream() {
      return (
        (this.getPassword &&
          this.getStream &&
          Object.keys(this.getStream).length) ||
        (this.$route &&
          this.$route.query &&
          this.$route.query.access &&
          this.user &&
          !Object.keys(this.user).length)
      );
    },
  },
  methods: {
    ...mapActions(["fetchStream", "fetchStreamPassword"]),
    modalToggle(name, state) {
      this[name] = state;
      bus.$emit("scrollLock", state);
    },
    toggleSendData(state) {
      this.modalToggle("sendData", state);
    },
    sendStreamData() {
      bus.$emit("validate", this._uid)
      let res = bus.data.result;
      if (!res.includes(false) && res.length) {
        if (this.fetchStream({ slug: "test-stream", auth: false, email: this.email, name: this.name })) {
          this.toggleSendData(false);
          this.openFrame = true;
        }
      }  
    },
    openStream() {
      if (
        this.$route &&
        this.$route.query &&
        this.$route.query.access &&
        this.user &&
        !Object.keys(this.user).length &&
        !this.getPassword
      ) {
        this.toggleSendData(true);
      } else {
        this.openFrame = true;
      }
    },
  },
  async mounted() {
    if (this.user && Object.keys(this.user).length) {
      this.fetchStream({ slug: "test-stream", auth: true });
    }
    if (this.$route && this.$route.query && this.$route.query.email) {
      this.email = this.$route.query.email;
    }
  },
  watch: {
    'user': {
      handler: async function (n, o) {
        if (this.user && Object.keys(this.user).length && !Object.keys(this.getStream).length) {
          this.fetchStream({ slug: "test-stream", auth: true });
        }
      },
      deep: true,
      immediate: true,
    }
  }
};
</script>

<style lang="scss" scoped>
.content {
  flex-grow: 1;
}
.event-detail {
  &__close-btn {
    cursor: pointer;
    position: absolute;
    top: 24px;
    left: 24px;
    z-index: 2;

    // @media screen and (max-width: 1220px) {
    //   top: 0;
    //   right: 50%;
    //   transform: translateX(50%);
    // }
  }

  &__translation {
    max-width: 280px;
    // width: 189px;

    @media screen and (max-width: 1220px) {
      width: auto;
    }

    @media screen and (max-width: 767px) {
      width: 100%;
      max-width: none;
    }
  }

  &__iframe {
    position: relative;
    width: 100%;
    display: none;

    & iframe {
      width: 100%;
      height: 800px;

      @media screen and (max-width: 1220px) {
        width: 100%;
        height: 100%;
      }
    }

    @media screen and (max-width: 1220px) {
      display: none;
    }
  }

  &__iframe_opened {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99999999;
    width: 100%;
    height: 100%;
    background-color: #fff;
    // padding: 64px;

    & iframe {
      height: 100%;
    }

    @media screen and (max-width: 1220px) {
      // padding: 30px 0 0;
    }
  }
}

.modal {
  &__item {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    @media screen and (max-width: 767px) {
      flex-direction: column;
    }
  }

  &__save {
    width: 117px;
    margin: 16px auto 0;

    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }

  &-item {
    &__title {
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
      color: #3c4242;
      width: 50px;
    }

    &__value {
      width: 280px;
      margin-left: 32px;

      @media screen and (max-width: 767px) {
        margin-left: 0;
      }
    }
  }
}
</style>
<style lang="scss">
.stream-data .modal__content {
  min-width: auto;
}
</style>